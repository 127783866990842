
import { defineComponent } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import MarkenBoxenMedia from '../../../components/Frontend/MarkenBoxenMedia.vue';




import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Unsere Media-Brands | Styria Media Group',
      keywords: ['medien-marken', 'media-brands' , 'kleine zeitung' , 'antenne steiermark' , 'die presse' , 'cope' , 'styria' , 'styria media group'],
      description: 'Von der Printzeitung über das Newsportal bis hin zur Content- und Performance-Agentur: Wir informieren. Wir unterhalten. Wir vernetzen.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Our media brands | Styria Media Group.',
      keywords: ['medien-brands', 'media-brands' , 'kleine zeitung' , 'antenne steiermark' , 'die presse' , 'cope' , 'styria' , 'styria media group'],
      description: 'From the print newspaper to the news portal to the content and performance agency: We inform. We entertain. We network.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    HeaderImageBoxGenerel,
    MarkenBoxenMedia,
    Footer,

    
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
